import classes from './About.module.scss';

export default function About() {

    return (
        <section id="About" className={classes.section}>

            <div className="sectionTitle">
                <p>About Me</p>
            </div>

            <hr /> {/* --------------------------------- */}

            <p className={classes.description}>
                Hello there, welcome to my corner of the internet! I'm Shahar, a graduate of Computer Science, a software engineer by day, and an enthusiast of trips, music, and gadgets all the time!
                I have always been interested and intrigued by computers and technology. Since I can remember, I've known what I wanted to do. I love coding, creating, math, and playing piano.
            </p>
            <p className={classes.description}>
                Feel free to poke around my <a href='https://github.com/shahar-shemesh' target='_blank'>GitHub</a>, contact me for a chat at <a href='mailto:shahar@usa.com'>shahar@usa.com</a>, or connect on <a href='https://linkedin.com/in/shaharshemesh' target='_blank'>LinkedIn</a> . Always looking to make new friends and meet new people!
            </p>
            
        </section>
    );
};

