import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { ReactTyped } from "react-typed";
import classes from './HeroSection.module.scss';

export default function HeroSection() {

    const [showFirstString, setShowFirstString] = useState(false);

    function handleComplete() {
        setShowFirstString(!showFirstString)
    }

    // useEffect(() => {
    //     // Set a timeout to show the second part after 5 seconds
    //     const timer = setTimeout(() => {
    //         setShowFirstString(true);
    //     }, 5000);

    //     return () => clearTimeout(timer);
    // }, []);



    return (
        <section id="heroSection" className={classes.heroSection}>

            <div className={classes.contentBox}>

                <div className={classes.content}>


                    <h1 className={classes.title}>
                        <p>Hey,{" "}</p>

                        <span className={classes.strings}>I'm{" "}

                            <span className={classes.titleColor}>
                                {!showFirstString && <ReactTyped
                                    strings={[
                                        `<span class=${classes.myName}>Shahar<span class=${classes.dot}>.</span></span>`,
                                        ""
                                    ]}
                                    typeSpeed={250}
                                    backSpeed={100}
                                    showCursor={false}
                                    loop={false}
                                    fadeOut
                                    onComplete={handleComplete}
                                />}

                                {showFirstString && (
                                    <ReactTyped
                                        strings={[
                                            "a Full Stack Developer.",
                                            "a Passionate Developer.",
                                            ""
                                        ]}
                                        typeSpeed={50}
                                        backSpeed={100}
                                        loop
                                        onComplete={handleComplete}
                                    />
                                )}
                            </span>
                        </span>
                    </h1>
                </div>
            </div>

            <div className={classes.img}>
                <motion.img
                    variants={{
                        hidden: { opacity: 0, y: 30 },
                        visible: { opacity: 1, y: 0 }
                    }}
                    initial="hidden"
                    animate="visible"
                    transition={{ type: 'spring', duration: 1, stiffness: 500, damping: 20 }}
                    src="./img/about-me.png" alt="Hero Section" />
            </div>

        </section>
    );
};