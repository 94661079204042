import classes from './MySkills.module.scss';

import DATA from "../data/index.json";

export default function MySkills() {

    return (
        <section id="mySkills">

            <div className="sectionTitle">
                <p>My Skills</p>
            </div>


            <hr /> {/* --------------------------------- */}


            <div className={classes.container}>
                {DATA?.skills?.map((item, index) => (
                    <div key={index} className={classes.card}>
                        <h3 className={classes.title}>{item.title}</h3>
                        <ul className={classes.stack}>
                            {item?.stack?.map((skill) => (
                                <li key={skill}>
                                    <i className={`bx bxl-${skill.toLowerCase()} bx-sm`}></i>
                                    {" " + skill}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

        </section>
    );
};