import { motion } from 'framer-motion';

import classes from '../Pages/MyPortfolio.module.scss';

export default function Card({ item }) {

    return (<>
        <motion.a layout href={item.gitSrc} target='_blank'>

            <li className={classes.card}>

                <img src={item.src} />

                <h3>{item.title}</h3>
                <p className={classes.cardDescription}>{item.description}</p>

                <p className={`${classes.portfolioLink}`}>
                    <span className={classes.action}>
                        <span>{item.link}</span>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 20 19"
                            fill="none"
                        >
                            <path
                                d="M4.66667 1.66675H18V15.0001M18 1.66675L2 17.6667L18 1.66675Z"
                                stroke="currentColor"
                                stroke-width="2.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </span>
                </p>
            </li>
        </motion.a>
    </>);
}